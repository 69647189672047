import axios from 'axios';
import getJWT from './token';

let api = `https://api.${process.env.REACT_APP_BASE_URL || "mattk.commerce-ims.com"}`;


const userHelpers = {

    getDashboardData: async function(company_id, token) {
        const userToken = await getJWT(token);
        console.log('get dashboard data');
        const url = `${api}/updatestatic/dashboard`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            let data = res.data;
            return data
        } catch (err) {
            return err;
        }
    },

    getUsers: async function(company_id, token) {
        const userToken = await getJWT(token);
        console.log('get users');
        const url = `${api}/admin/users`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            let users = res.data;
            users.forEach((user, i) => {
                user['id'] = i;
            })
            return users
        } catch (err) {
            return err;
        }
    },
    
    deleteUser: async function(company_id, user_id, token) {
        console.log('delete user');
        const userToken = await getJWT(token);
        const url = `${api}/admin/users/${user_id}`;
        try {
            let res = await axios.delete(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return err;
        }
    },

    updateUser: async function(company_id, user_id, groups, token) {
        console.log('updating user');
        const userToken = await getJWT(token);
        const url = `${api}/admin/users/${user_id}`;
        try {
            let res = await axios.put(url, groups, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } } )
            if (res.status === 200){
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return err;
        }
    }

}

export default userHelpers;