import {React} from "react";
import Box from '@mui/material/Box';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton'


function HelpButton(props) {

    return(
        <Box style={{backgroundColor: props.help ? "white" : "", width:"fit-content"}} display="flex">
            <IconButton onClick={() => props.setHelp(!props.help)}>
                <HelpIcon style={{margin:"auto", color: !props.help ? "white" : ""}} />
            </IconButton>
        </Box>
    )
}

export default HelpButton;