import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FailedTrackingTable from './FailedTrackingTable';
import TrackingPopup from './TrackingPopup';
import InfoPopup from './InfoPopup';
import orderHelpers from '../../helpers/orders';
import Alert from '@mui/material/Alert';


function FailedTracking(props) {
    const [tracking, setTracking] = useState([]);
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    const [error, setError] = useState("");
    const [customer, setCustomer] = useState("");

    useEffect(()=> {
        getTracking();
    }, [])

    const handleSelect = async(sel) => {
        let index = sel.length - 1;
        if(index < 0) setSelected([]);
        else setSelected([sel[sel.length - 1]]);
        return;
    }

    const getTracking = async() => {
        let tracking = await orderHelpers.getFailedTracking(props.company, props.warehouse, props.token);
        setTracking(tracking);
        setSelected([]);
    }

    const updateTracking = async(tracking_number) => {
        setError("");
        if(tracking_number === "") return;
        let order_id = tracking[selected].order_id;
        try{
            await orderHelpers.setTracking(props.company, order_id, tracking_number, props.token);
        }
        catch(e) {
            console.log(e);
            setError("Failed to set tracking.");
        }
        finally {
            setTracking([]);
            setOpen(false);
            await getTracking();
        }
    }

    const handleClose = async() => {
        setOpen(false);
        setInfoOpen(false);
        setCustomer({});
    }

    const regenerateLabels = async() => {
        props.setLoading(true);
        await orderHelpers.redoSingleTracking(props.company, {internal_po_number: tracking[selected[0]].internal_po_number, vendor_name: tracking[selected[0]].vendor_name}, props.token)
        props.setLoading(false);
    }

    const updateCustomer = async(cust) => {
        props.setLoading(true);
        await orderHelpers.updateCustomerInfo(props.company, {internal_po_number: tracking[selected[0]].internal_po_number, customer: cust}, props.token)
        props.setLoading(false);
    }

    const openInfo = async() => {
        setInfoOpen(true);
        let temp = tracking[selected[0]]
        setCustomer({
            name: temp.name,
            address1: temp.address1,
            address2: temp.address2,
            city: temp.city,
            state: temp.state,
            zip_code: temp.zip_code
        })
    }

    const regenerateAll = async() => {
        props.setLoading(true);
        let pos = [];
        for(let order of tracking) {
            let po = order.internal_po_number;
            let vendor_name = order.vendor_name;
            if(pos.includes(po)) continue;
            pos.push(po);
            await orderHelpers.redoSingleTracking(props.company, {internal_po_number: po, vendor_name: vendor_name}, props.token)
        }
        props.setLoading(false);
    }


        return(
            <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Failed Tracking</h1> 
                    {error !== "" ? <Alert severity="error">{error}</Alert> : null}
                    {selected.length === 1 ? 
                    <Box>
                        <Button sx={{ml:"10%"}} variant="contained" className={`${props.warehouse} ${props.company} tertiary`} onClick={()=>setOpen(true)}>Manually Update Tracking</Button> 
                        <Button sx={{ml:"10%"}} variant="contained" className={`${props.warehouse} ${props.company} secondary`} onClick={()=>regenerateLabels()}>Regenerate Labels</Button> 
                        <Button sx={{ml:"10%"}} variant="contained" className={`${props.warehouse} ${props.company} info`} onClick={()=>openInfo()}>Update Address Information</Button> 
                    </Box>: null}
                    {tracking.length > 0 ? 
                        <Button sx={{ml:"10%"}} variant="contained" className={`${props.warehouse} ${props.company} primary`} onClick={()=>regenerateAll()}>Regenerate All Tracking</Button> 
                        : null}
                    <TrackingPopup {...props} handleClose={handleClose} updateTracking={updateTracking} open={open}/>
                    <InfoPopup customer={customer} setCustomer={setCustomer} {...props} handleClose={handleClose} updateCustomer={updateCustomer} open={infoOpen}/>
                    <FailedTrackingTable getTracking={getTracking} selected={selected} tracking={tracking} handleSelect={handleSelect} {...props} />
                </Box>
            </Box>
        )
}

export default FailedTracking;