import React, {useState} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

function TransferDialog(props) { 

    const [checked, setChecked] = useState({});
    const items = props.items || [];

    const handleSelect = function(order_id) {
        let temp = JSON.parse(JSON.stringify(checked));
        if(!(order_id in temp)) temp[order_id] = false;
        temp[order_id] = !temp[order_id];
        setChecked(temp);
    }

    return(
        <Dialog open={props.open} onClose={()=>props.setOpen(false)}>
            <DialogTitle>
                    Select Items to Transfer
            </DialogTitle>
            <DialogContent>
                <List>
                    {items ? items.map((item, index) => {
                        return (
                            <ListItem key={index} disablePadding>
                                <ListItemButton onClick={() => handleSelect(item.order_id)} dense>
                                    <ListItemIcon>
                                        <Checkbox edge="start" checked={checked[item.order_id] === true} />
                                    </ListItemIcon>
                                    <ListItemText sx={{mr:"5px"}}>
                                        {item.internal_sku}
                                    </ListItemText>
                                    <ListItemText sx={{overflowX:"hidden"}}>
                                        {item.description}
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                    }) : null }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.setOpen(false)}>Cancel</Button>
                <Button onClick={()=>props.transfer(checked)} autoFocus>
                    Transfer
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default TransferDialog;

