import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import {lighten } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip"

const columns = [ 
    {
        field: 'build_id',
        headerName: 'ID',
        flex: 1,
        editable: false,
        minWidth: 50
    }, 
    {
        field: 'user',
        headerName: 'User',
        flex: 1,
        editable: false,
        minWidth: 150
    }, 
    {
        field: 'date',
        headerName: 'Start Date',
        renderCell: (params) => {
            let date = new Date(params.row.date).toLocaleString("en-US", {timeZone: "America/Los_Angeles"}).replace("T", " ").replace("Z", " ").replace(".000", "");
            return(
                <Tooltip title={date}>
                    <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{date}</Box>
                </Tooltip>
            )
        },
        flex: 1,
        editable: false,
        minWidth: 150
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        editable: false,
        minWidth: 100
    }
]


function AutobuildTable(props) {


    return(
        <Box display="flex" flexDirection="column">

        <Box> 
            <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} sx={{ 
                    '& .warning': {
                        bgcolor: (theme) => lighten("#FFFF00", 0.5)
                    },
                    '& .error': {
                        bgcolor: (theme) => lighten(theme.palette.warning.light, 0.5)
                    }
                }}>
                <DataGrid
                rows={props.builds}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {false}
                getRowClassName={(params) => 
                    {
                        if(params.row.status === "RUNNING") return "warning";
                        if(params.row.status === "COMPLETE") return "";
                        if(params.row.status === "FAILED") return "error";
                    }}
                sortModel = {[{field:'build_id', sort: 'desc'}]}
                />
            </Box>
        </Box>
    </Box>
    )
    
}

export default AutobuildTable;