import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BulkStatusTable from './BulkStatusTable';

function BulkStatus(props) {

    
    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Bulk Status</h1> 
                <BulkStatusTable {...props} />
            </Box>
        </Box>
    )
}

export default BulkStatus;