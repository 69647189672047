import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BulkOrdersTable from './BulkOrdersTable';

function BulkOrders(props) {

    
    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Bulk Orders</h1> 
                <BulkOrdersTable {...props} />
            </Box>
        </Box>
    )
}

export default BulkOrders;