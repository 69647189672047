import {React} from "react";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

function Helpbar(props) {

    const ids = {
        dropship: "1vwSE1-2I9dxwPj5teKa7j7yLD8sFs_XqWlhd19o3MBs",
        bulk: "1Y15024ZEkrgSK51TWuofjHhEzhQtoaH_BnkNxi8VCdU",
        admin: "1lZaFr3O96f0zZ1Ol73XJFWxcGrlGWzrD2-nG6g6mpVU",
        inventory: "1z7IwP8lFDc3IVlsJDshtLIflJYaJh9G7vZeLwLWZTTQ"
    }

    const topics = {
        dropship: [
            {
                name: "How to Upload Files",
                link: "h.slftbpb97x1y",
            },
            {
                name: "How to Get Tracking For Direct Uploads",
                link: "h.1qjdvon3nu5"
            },
            {
                name: "Dealing With Upload Errors",
                link: "h.5btiqq8w17t"
            },
            {
                name: "Checking API Uploads",
                link: "h.kn6783ns61w"
            },
            {
                name: "Searching For Orders",
                link: "h.t8g1xyi7gedc"
            },
            {
                name: "Creating a Pull Sheet",
                link: "h.dqf2kp6ku4rl"
            }
        ],
        bulk: [
            {
                name: "Creating Box Labels",
                link: "h.ui1ubnba0qr7"
            }
        ],
        admin: [
            {
                name: "Creating a New User",
                link: "h.sfh9xba6c7bt"
            },
            {
                name: "Updating a User's Permissions",
                link: "h.weym3baoc9ru"
            },
            {
                name: "Deleting a User",
                link: "h.md75csp4pfhe"
            }
        ],
        inventory: [
            {
                name: "Updating the Database",
                link: "h.6ajxsleyisqv"
            }
        ]
    }

    return(
        <Drawer ModalProps={{keepMounted: true}} sx={{width: '200px', flexShrink: 0,  zIndex: props.zIndex}} anchor="right" open={props.help} onClose={()=>props.setHelp(false)}>
            <List sx={{width:"400px", mt:"20px", ml:"10px"}}>
                <h1>Help Topics:</h1>
                {
                    Object.keys(topics).map((topic, index) => {
                        if(props.groups.includes(topic)) return (
                            <Box key={index + 1}>
                                <h3>{topic[0].toUpperCase() + topic.substring(1)}</h3>
                                <Divider />
                                {
                                    topics[topic].map((obj, index2) => {
                                        return (
                                            <Box key={index2+1}>
                                                <Link underline="hover" target="_blank" href={`https://docs.google.com/document/d/${ids[topic]}#heading=${obj.link}`}>{obj.name}</Link>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        )
                        return null;
                    })
                }
            </List>
        </Drawer>
    )
}

export default Helpbar;

