import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import StaticStatusTable from './StaticStatusTable';
import inventoryHelpers from '../../helpers/inventory';

class Inventory extends Component {

    state = {
        updates: [
        ]
    }

    componentDidMount(){
        const user = this.props.user;
        this.setState({user});
        this.getStaticUpdates();
        this.setState({loading: false});
    }

    handleChange = (e) => {
        this.setState({
            staticType: e.target.value
        })
    }

    updateStaticFile = async () => {
        if(!this.state.staticType ){
            console.log('select a file')
            return;
        }
        this.props.setLoading(true);
        let update = await inventoryHelpers.updateStaticFile(this.props.company, this.state.staticType, this.props.user, this.props.token)
        if(update === true) {
            this.getStaticUpdates();
        } else {
            this.setState({err: `Error updating static file: ${update}`})
        }
        this.props.setLoading(false);
    }

    getStaticUpdates = async () => {
        this.props.setLoading(true);
        console.log('get static file updates')
        this.setState({loading: true})
        let res = await inventoryHelpers.getStaticUpdates(this.props.company, this.props.token);
        if(res.success){
            res.data.sort((a, b) => (new Date(a.date)).getTime() - (new Date(b.date)).getTime());
            let data = res.data.map((obj, index) => {
                obj.id = index;
                obj.date = obj.date.replace("T", " ").slice(0, obj.date.indexOf("."));
                return obj;
            });
            this.setState({updates: data})
            this.setState({loading: false})
        } else {
            this.setState({error: true, error_message: res.error})
            this.setState({loading: false})
        }
        this.props.setLoading(false);

    }

    exportInventory = async() => {
        this.props.setLoading(true);
        let res = await inventoryHelpers.getExport(this.props.company, this.props.token);
        let date = new Date().toISOString();
        if(res.success) {
            for(let type in res.data) {
                let headers = Object.keys(res.data[type][0]);
                let out = [headers];
                for(let obj_row of res.data[type]) {
                    let row = [];
                    for(let header of headers) {
                        let cell = 0;
                        if(obj_row[header] > 0) cell = obj_row[header];
                        row.push(cell);
                    }
                    out.push(row.join(","));
                }
                let csv = out.join("\n");
                let blob = new Blob([csv], {type: "text/csv"})
                let uri = window.URL.createObjectURL(blob);
                console.log("URI", uri)
                let a = document.createElement("a");
                a.setAttribute("href", uri);
                a.setAttribute("download", type + "_inventory_export_" + date + ".csv");
                document.body.appendChild(a);
                a.click();
            }
            
        }
        else {
            this.setState({error: true, error_message: res.error});
        }
        this.props.setLoading(false);
    }

    updateAcumatica = async() => {
        this.props.setLoading(true);
        await inventoryHelpers.updateAcumatica(this.props.company, this.props.token);
        this.props.setLoading(false);
    }

    render(){
        return(
            <Box className={this.props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
                <Box className={this.props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Inventory Management</h1>
                    <Box>
                    <FormControl required sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="file-select">File</InputLabel>
                        <Select
                            label="File *"
                            onChange={this.handleChange}
                            defaultValue=''
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'updateMaster'}>Master File</MenuItem>
                            <MenuItem value={'updateInventory'}>Inventory</MenuItem>
                            <MenuItem value={'forceInventory'}>Force Inventory</MenuItem>
                            <MenuItem value={'updateVendor'}>Vendors</MenuItem>
                            <MenuItem value={'updateZipcode'}>Zip Codes</MenuItem>
                        </Select>
                        <FormHelperText>Required</FormHelperText>
                    </FormControl>
                    <Button variant="contained" size = "large" color="success" sx = {{marginTop: 1.6}} onClick={this.updateStaticFile}>Update</Button>
                    </Box>
                    <Button variant="contained" size="large" color="info" sx={{marginTop: 1.6}} onClick={()=>this.updateAcumatica()}>Pull Acumatica Counts</Button>
                    <Button variant="contained" size="large" color="secondary" sx={{marginLeft: 1.6, marginTop: 1.6}} onClick={()=>this.exportInventory()}>Export Inventory</Button>
                    <StaticStatusTable 
                        isMobile={this.props.isMobile}
                        updates={this.state.updates}
                        getStaticUpdates={this.getStaticUpdates}
                    />
                </Box>
            </Box>
        )
    }
}

export default Inventory;