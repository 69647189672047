import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import bulkHelpers from "../../helpers/bulk";


function UploadForm(props) {
    const [file, changeFile] = useState(null)

    function clearFile() {
        changeFile(null);
    } 

    return (
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
            <input  onChange={(event) => changeFile(event.target.files[0])} accept=".csv, .xlsx, .xlx" className="fileUpload" style={{display: 'none'}} id="contained-button-file" type="file" />
            <label htmlFor="contained-button-file">
                <Button style={{width:props.isMobile ? "100px" : "50%"}} color="success" variant="contained" component="span" className="uploadButton">Upload</Button>
            </label>
            {file ? <Box display="flex" style={{margin:"auto", marginTop:"10px"}}><h4 style={{margin:"auto"}}>{file.name}</h4> <Button variant="outlined" color="error" size="small" style={{fontSize:"10px", margin:"auto", marginLeft:"10px"}} onClick={()=>clearFile()}>Delete</Button></Box> : null}
            <Button disabled={props.isLoading || file === null} style={{marginTop:"20px", width:props.isMobile ? "100px" : "50%"}} variant="contained" color="secondary" onClick={() => {bulkHelpers.submitFile(file, changeFile, props.company + "_bulk", props.user.name, props.token)}}>Submit</Button>
        </Box>
    )
}

export default UploadForm;