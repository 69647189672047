import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function BugReport(props) {

    function reportBug(severity="") {
        let a = document.createElement('a');
        a.href=`mailto:ims@furhaven.com?subject=${severity} *REASON*&body=Replace *REASON* in the subject with a brief summary, then describe the bug here.`
        a.setAttribute("target", "_blank");
        a.click();
    }

    return(
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Bug Report</h1>
                <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h2>To submit a bug report, click one of the buttons below, or send an email to IMS@furhaven.com.</h2>
                    <Box display="flex" style={{width:"80%", margin:"auto"}} className={props.isMobile ? "mobile-box" : "desktop-box"}>
                        <Button style={{margin:"auto"}} onClick={()=>reportBug("Bug: ")} className={`${props.warehouse} ${props.company} secondary`} variant="contained">Minor Bug</Button>
                        <Button style={{margin:"auto"}} onClick={()=>reportBug("Major Bug: ")} className={`${props.warehouse} ${props.company} tertiary`} variant="contained">Major Bug</Button>
                        <Button style={{margin:"auto"}} onClick={()=>reportBug("!!CRITICAL BUG!!: ")} className={`${props.warehouse} ${props.company} error`} variant="contained">Critical Bug</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default BugReport;