import {React, useState, useEffect} from "react";
import Box from '@mui/material/Box';
import AutobuildTable from './AutobuildTable';
import Button from '@mui/material/Button';
import acumaticaHelpers from "../../helpers/acumatica";

function Autobuild(props) {

    const [builds, setBuilds] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const setLoading = props.setLoading;
    const company = props.company;
    const token = props.token;

    useEffect(() => {
        const getBuilds = async() => {
            setLoading(true);
            let new_log = await acumaticaHelpers.getAutobuilds(company, token);
            setBuilds(new_log);
            setLoading(false);
        }
        getBuilds();
    }, [refresh, company, token, setLoading])

    const startAutobuild = async() => {
        props.setLoading(true);
        await acumaticaHelpers.startAutobuild(company, token);
        props.setLoading(false);
        setRefresh(!refresh);
    }

    const alert = async() => {
        if (window.confirm("Start Autobuild?")) {
            startAutobuild();
        }
    }

    return (
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{ display: 'flex', flexDirection: 'column'}}>
                <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Acumatica AutoBuild</h1> 
                    <Box>
                        <Button className={`${props.company} ${props.warehouse} secondary`} variant="contained" onClick={() => setRefresh(!refresh)}>Refresh</Button>
                        <Button className={`${props.company} ${props.warehouse} tertiary`} sx={{ml:"20px"}} variant="contained" onClick={() => alert()}>Start AutoBuild</Button>
                    </Box>
                    <AutobuildTable {...props} builds={builds} />
                </Box>
            </Box>
    )
}

export default Autobuild;