import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CarrierBucket from './CarrierBucket';

function Unprocessed(props) {
    const [openWarehouse, setOpenWarehouse] = useState(-1);

    return (
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
            <h4>Unprocessed Order Counts:</h4>
            <Box display="flex">
            {props.warehouses.map((obj, index) => {
                if(props.warehouse === obj || props.warehouse === "All") {
                    let wh = {
                        short: obj === "kentucky" ? "KY" : obj === "pennsylvania" ? "PA" : null,
                        name: obj === "kentucky" ? "Kentucky" : obj === "pennsylvania" ? "Pennsylvania" : null
                    }
                    return (
                        <Box key={index} sx={{mb: "40px"}}>
                            <Button sx={{ml: "20px"}} variant="contained" className={`${props.company} ${obj} primary`} onClick={()=>setOpenWarehouse(index)}>{wh.name}</Button>
                            <Drawer anchor={"bottom"} open={openWarehouse === index} onClose={()=>setOpenWarehouse(-1)}>
                                <CarrierBucket 
                                    warehouse={wh.short} 
                                    counts={props.counts ? props.counts[wh.name] : {}} 
                                />
                            </Drawer>
                        </Box>
                    )
                }
                return null;
            })}
            </Box>
        </Box>
    )

}


export default Unprocessed;