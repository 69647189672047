import axios from 'axios';
import getJWT from './token';

let api = `https://api.${process.env.REACT_APP_BASE_URL || "mattk.commerce-ims.com"}`;


const inventoryHelpers = {
    updateStaticFile: async function(company_id, staticType, user, token){
        const userToken = await getJWT(token);
        console.log('update file')
        const url = `${api}/updatestatic/${staticType}`
        try {
            let res = await axios.put(url, {user: user.attributes.name}, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return err;
        }
    },

    getExport: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/updatestatic/inventory`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return {
                success: true, 
                data: res.data
            }
        } catch (err) {
            return {
                success: false, 
                error: err.response.data.error.code
            }
        }
    },

    getStaticUpdates: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/updatestatic/actions`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return {
                success: true, 
                data: res.data
            }
        } catch (err) {
            return {
                success: false, 
                error: err.response.data.error.code
            }
        }
    },

    updateAcumatica: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/acumatica/inventory`
        try {
            let res = await axios.put(url, '{}', { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return {
                success: true, 
            }
        } catch (err) {
            return {
                success: false, 
                error: err.response.data.error.code
            }
        }
    }


}

export default inventoryHelpers;