import {React, useState} from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

function TrackingPopup(props) {

    const [tn, setTn] = useState("");

        const handleChange = async(e) => {
            setTn(e.target.value);
        }


        return(
            <Dialog open={props.open} onClose={()=>props.handleClose()}>
            <DialogTitle>
                    Enter tracking number for the selected item:
            </DialogTitle>
            <DialogContent>
                <Input onChange={(e)=>handleChange(e)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.handleClose()}>Cancel</Button>
                <Button onClick={()=>props.updateTracking(tn)} autoFocus>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
        )
}

export default TrackingPopup;