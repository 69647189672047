import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {React} from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import User from './User';

function Warehouses(props) {

    function capitalize(string) {
        if(!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    function handleCompanyChange(event) {
        props.setCurrentCompany(event.target.value);
    }

    function handleWarehoueChange(event) {
        props.setCurrentWarehouse(event.target.value);
    }

    return(
        <Box sx={{boxShadow: props.isMobile ? 3 : 0 }} display="flex" flexDirection={props.isMobile ? "column" : "row"} style={{width:"100%"}}>
            {props.companies.length <= 1 ? 
                        <Typography variant="h6" component="div" style={{marginTop:"auto", marginBottom:"auto", marginLeft:"50px", marginRight:"25px", height:"fit-content"}}>
                            {capitalize(props.company)}
                        </Typography>
                    : 
                        <FormControl style={{margin:"auto", marginLeft:"25px", marginRight:"50px", width:"150px"}}>
                            <Select style={{textAlign:"center", color:props.isMobile ? "black" : "white", fontSize:"1.25rem"}} disableUnderline={true} variant="standard" value={props.company} onChange={handleCompanyChange}>
                                {props.companies.map((company, index) => <MenuItem key={index} value={company}>{capitalize(company)}</MenuItem>)}
                            </Select>
                        </FormControl>
                    }
                    {props.isMobile ? <Divider flexItem />
                        : <Divider orientation="vertical" flexItem /> }
                    {props.warehouses[props.company].length <= 1 ?
                        <Typography variant="h6" component="div" style={{margin:"auto", marginLeft:"25px", marginRight:"50px", height:"fit-content"}}>
                            {capitalize(props.warehouse)}
                        </Typography>
                    :
                        <FormControl style={{margin:"auto", marginLeft:"25px", marginRight:"50px", width:"150px"}}>
                            <Select style={{textAlign:"center", color:props.isMobile ? "black" : "white", fontSize:"1.25rem"}} disableUnderline={true} variant="standard" value={props.warehouse} onChange={handleWarehoueChange}>
                                <MenuItem key={0} value="All">All</MenuItem>
                                {props.warehouses[props.company].map((warehouse, index) => <MenuItem key={index+1} value={warehouse}>{capitalize(warehouse)}</MenuItem>)}
                            </Select>
                        </FormControl>
                    }
                    {props.isMobile ? null
                        : <Divider orientation="vertical" flexItem /> }
                    <User {...props} />
        </Box>
    )
}

export default Warehouses;