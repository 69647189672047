import {React} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function DashboardCard(props) {


        return(
            <Card sx={{ml:'15px'}} className={props.isMobile? "mobile-card" : "card"}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {props.data.key}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {props.data.value || 0}
                    </Typography>
                </CardContent>
            </Card>
        )
    
}

export default DashboardCard;