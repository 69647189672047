import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function PermissionsTable(props) {

    function checkPrivilege(value){
        if(value){
            return (
                <CheckIcon />
            )
        } else {
            return <CloseIcon />
        }
    }

    
    const columns = props.scopes.map((obj, index) => {
        let column = {
            field: obj,
            headerName: obj[0].toUpperCase() + obj.substring(1),
            editable: false
        }
        if(obj !== "name" && obj !== "email") {
            column.renderCell = (params) => {
                return checkPrivilege(params.value);
            }
            column.width = 100
        }
        else {
            column.width = 200
        }
        return column;
    })
    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'row'}}>
            <div className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{ height: 1000, marginTop: '50px'}}>
                <DataGrid
                className={props.isMobile ? "mobile-table" : "desktop-table"} 
                rows={props.users}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {true}
                onSelectionModelChange = {(selectionModel, details) => {
                    props.selectUser(selectionModel)
                }}
                />
            </div>            
        </Box>
    )
}

export default PermissionsTable