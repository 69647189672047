import axios from 'axios';
import getJWT from './token';

let api = `https://api.${process.env.REACT_APP_BASE_URL || "mattk.commerce-ims.com"}`;


const logHelpers = {

    getLog: async function(company_id, token) {
        const userToken = await getJWT(token);
        console.log('get log');
        const url = `${api}/admin/log`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            let log = res.data;
            log.forEach((row, i) => {
                row['id'] = i;
            })
            return log
        } catch (err) {
            return err;
        }
    }

}

export default logHelpers;