import {React, useState, cloneElement}from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {Auth} from 'aws-amplify';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BookIcon from '@mui/icons-material/Book';
import SearchIcon from '@mui/icons-material/Search';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BugReportIcon from '@mui/icons-material/BugReport';
import Warehouses from './Warehouses';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import '../../css/Sidebar.css'


function Sidebar(props) {

    const [hover, setHover] = useState(-1);
    const navigate = useNavigate();

    function setClasses(item) {
      if(item === props.route) {
        return `listItem ${props.company} ${props.warehouse} active`
      } else {
        return `listItem ${props.company} ${props.warehouse}`
      }
    }

    const logOut = async() => {
      await Auth.signOut();
      props.clearUser();
      navigate('/login');
    }

    const pages = [
      {
        type: "Dropship",
        pages: [{
        title: "uploads",
        text: "Uploads",
        scopes: ["admin", "dropship"],
        link: "/uploads",
        icon: <UploadFileIcon />

      },
      {
        title: "api_status",
        text: "API Status",
        scopes: ["admin", "dropship"],
        link: "/api_status",
        icon: <CloudUploadIcon />
      },
      {
        title: "failed_tracking",
        text: "Failed Tracking",
        scopes: ["admin", "dropship"],
        link: "/failed_tracking",
        icon: <CancelScheduleSendIcon />
      },
      {
        title: "pullsheets",
        text: "Pull Sheets",
        scopes: ["admin", "dropship"],
        link: "/pullsheets",
        icon: <ListAltIcon />
      },
      {
        title: "order_search",
        text: "Order Search",
        scopes: ["admin", "dropship", "warranties"],
        link: "/order_search",
        icon: <SearchIcon />
      }]},
      {
        type: "Bulk",
        pages: [
          {
            title: "bulk_orders",
            text: "Bulk Orders",
            scopes: ["admin", "bulk"],
            link: "/bulk_orders",
            icon: <AddShoppingCartIcon />
          },
          {
            title: "bulk_status",
            text: "Bulk Status",
            scopes: ["admin", "bulk"],
            link: "/bulk_status",
            icon: <MonitorHeartIcon />
          },
          {
            title: "box_labels",
            text: "Box Labels",
            scopes: ["admin", "bulk", "inventory"],
            link: "/box_labels",
            icon: <BookIcon />
          }    
      ]},
      {
        type: "Utiltity",
        pages: [{
        title: "inventory",
        text:"Inventory",
        scopes: ["admin", "inventory"],
        link: "/inventory",
        icon: <InventoryIcon />
      }]},
      {
        type: "Acumatica",
      pages:[{
        title: "autobuild",
        text:"AutoBuild",
        scopes: ["admin", "acumatica"],
        link: "/autobuild",
        icon: <BuildCircleIcon />
      }]},
      {
        type: "Admin",
        pages:[{
        title: "users",
        text: "Users",
        scopes: ["admin"],
        link: "/users",
        icon: <SupervisorAccountIcon />
      },
      {
        title: "log",
        text: "Log",
        scopes: ["admin"],
        link: "/log",
        icon: <MyLocationIcon />
      }
    ]
  }]

    return(
      <Drawer
        variant={props.isMobile ? 'temporary' : 'permanent'}
        ModalProps={{keepMounted: true}}
        anchor='left'
        sx={{width: '200px', flexShrink: 0,  zIndex: props.zIndex}}
        open={props.open}
        onClose={() => props.toggle(false)}
      >
        <List style={{paddingTop:"0", width:"100%"}}>
          <Box display="flex">
            <ListItem onClick={()=>props.toggle(false)} component={Link} to="/" sx={{height:"50px", padding:"0"}} className={`${props.company} ${props.warehouse} primary`}>
                <Typography variant='h4' style={{color:"white", fontFamily:"Century Gothic", fontWeight: "bold", margin:"auto", height:"fit-content"}}>IMSv2</Typography>
            </ListItem>
          </Box>
          </List>
          <List id="scrollmenu" style={{paddingTop:"0",overflowY:"scroll"}}>

          {props.isMobile ? 
            <Warehouses {...props} />
            : null
          }
          {pages.map((outer, index) => {
            let menu_items = outer.pages.map((obj, inner) => {
              let inScope = props.scopes.some(ele => obj.scopes.includes(ele));
              let inner_index = index * 100 + inner;
              if(inScope) {
                return(
                  <Box key={inner_index}>
                    <ListItem  onMouseOver={() => setHover(inner_index)} onMouseLeave={() => setHover(-1)} onClick={()=>props.toggle(false)} className={setClasses(obj.title)} component={Link} to={obj.link}>
                      <ListItemIcon>
                        {cloneElement(obj.icon, {sx:{color:hover === inner_index ? "white" : ""}})}
                      </ListItemIcon>
                      <ListItemText primary={obj.text}></ListItemText>
                    </ListItem>
                    <Divider />
                  </Box>
                )
              }
            })
            if(menu_items.every(obj => !obj)) return null;
            return (
              <Box key={index} sx={{paddingBottom:"5px"}}>
                <ListItem style={{backgroundColor:"#f1eeee"}}>
                  <Typography variant="h6" style={{width:"100%", padding:"0"}}>
                    {outer.type}
                  </Typography>
                </ListItem>
                {menu_items}
              </Box>
            )
          })}
          </List>
          <Divider />
          <List style={{marginTop: 'auto'}}>
          <ListItem  onMouseOver={() => setHover(pages.length + 1)} onMouseLeave={() => setHover(-1)} onClick={()=>props.toggle(false)} className={setClasses("bugreport")} component={Link} to={"/bugreport"}>
              <ListItemIcon>
                <BugReportIcon sx={{color:hover === pages.length + 1 ? "white" : ""}} />
              </ListItemIcon>
              <ListItemText primary="Report Error"></ListItemText>
            </ListItem>
            <Divider />          
            <ListItem onMouseOver={() => setHover(pages.length + 2)} onMouseLeave={() => setHover(-1)} className={setClasses('signout')} onClick={() => logOut()} >
            <ListItemIcon>
              <SupervisorAccountIcon sx={{color:hover === pages.length + 2 ? "white" : ""}} />
            </ListItemIcon>
            <ListItemText primary='Sign Out'></ListItemText>
          </ListItem>
        </List>
      </Drawer>
    )
}

export default Sidebar;