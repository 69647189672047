import {React, useState} from 'react';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

function EditUser(props) {

    const [name, setName] = useState(props.user.name)

    const [furhaven, setFurhaven] = useState(props.user.furhaven)
    const [worldwise, setWorldwise] = useState(props.user.worldwise)
    const [kentucky, setKentucky] = useState(props.user.kentucky)
    const [pennsylvania, setPennsylvania] = useState(props.user.pennsylvania)
    const [admin, setAdmin] = useState(props.user.admin)
    const [dropship, setDropship] = useState(props.user.dropship)
    const [bulk, setBulk] = useState(props.user.bulk)
    const [inventory, setInventory] = useState(props.user.inventory)
    const [warranties, setWarranties] = useState(props.user.warranties)
    
        return(
            <Box sx = {{display: 'flex', flexDirection: 'row', width:"500px"}}>
                <Box display="flex" flexDirection="column" component = 'form' style={{padding:"10px", margin:"auto"}}>
                    <Box sx = {{display: 'flex', flexDirection: 'column'}} style={{padding:"10px"}}>
                        <h3>{`Edit User: ${props.user.name}`}</h3>
                        <TextField 
                            required
                            name='name'
                            label='Name'
                            onChange = {(e)=>setName(e.target.value)}
                            defaultValue = {name}
                            sx = {{marginBottom: '10px'}}
                        />
                    </Box>
                    <FormGroup>
                        <Box display="flex" style={{width:"100%"}}>
                            <Box display="flex" flexDirection="column" style={{marginRight:"auto", marginLeft:"0"}}>
                                <FormControlLabel onClick={(e)=>setFurhaven(!furhaven)} control={<Checkbox defaultChecked={props.user.furhaven} />} name='furhaven' label="Furhaven" />
                                <FormControlLabel onClick={(e)=>setWorldwise(!worldwise)} control={<Checkbox defaultChecked={props.user.worldwise} />} name='worldwise' label="Worldwise" />
                                <FormControlLabel onClick={(e)=>setKentucky(!kentucky)} control={<Checkbox defaultChecked={props.user.kentucky} />} name='kentucky' label="Kentucky" />
                                <FormControlLabel onClick={(e)=>setPennsylvania(!pennsylvania)} control={<Checkbox defaultChecked={props.user.pennsylvania} />} name='pennsylvania' label="Pennsylvania" />
                            </Box>
                            <Box display="flex" flexDirection="column" style={{marginLeft:"auto", marginRight: "0", paddingLeft:"30px", borderLeft:"1px solid black"}}>
                                <FormControlLabel onClick={(e)=>setAdmin(!admin)} control={<Checkbox defaultChecked={props.user.admin} />} name='admin' label="Admin" />
                                <FormControlLabel onClick={(e)=>setDropship(!dropship)} control={<Checkbox defaultChecked={props.user.dropship} />} name='dropship' label="Dropship" />
                                <FormControlLabel onClick={(e)=>setBulk(!bulk)} control={<Checkbox defaultChecked={props.user.bulk} />} name='bulk' label="Bulk" />
                                <FormControlLabel onClick={(e)=>setInventory(!inventory)} control={<Checkbox defaultChecked={props.user.inventory} />} name='inventory' label="Inventory" />
                                <FormControlLabel onClick={(e)=>setWarranties(!warranties)} control={<Checkbox defaultChecked={props.user.warranties} />} name='warranties' label="Warranties" />
                            </Box>
                        </Box>
                    </FormGroup>
                    <Box display="flex" style={{margin:"auto", width:"100%", marginTop:"20px"}}>
                        <Button variant='contained' onClick={() => props.editUser(false)} color="success" style={{margin:"auto", marginLeft:"10px"}}>Cancel</Button>
                        <Button variant='contained' onClick={() => props.updateUser(props.user, name, furhaven, worldwise, kentucky, pennsylvania, admin, dropship, bulk, inventory, warranties)} color="success" style={{margin:"auto", marginRight:"10px"}}>Submit</Button>
                    </Box>
                </Box>
                
            </Box>
        )
    
}

export default EditUser