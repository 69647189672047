import {React, useState} from 'react';
import Box from '@mui/material/Box';

import Sidebar from './Sidebar';
import LinearProgress from '@mui/material/LinearProgress';
import Bar from './Bar';
import Helpbar from './Helpbar';

function IMSToolbar(props) {
    const [openMenu, setOpenMenu] = useState(false);
    const [help, setHelp] = useState(false);

    return (
        <Box  sx={{ flexGrow: 1}}>
            <Bar {...props} zIndex={2} openMenu={openMenu} setOpenMenu={setOpenMenu} help={help} setHelp={setHelp} />
            {props.isLoading ? <LinearProgress position="absolute" style={{top: "30px", left: "0", width: "100%"}} color="success" /> : null}
            <Sidebar {...props} zIndex={3} route={props.route} clearUser={props.clearUser} open={openMenu} toggle={setOpenMenu} scopes={props.groups} active={props.route} logoutHandler={props.logout} />            
            <Helpbar {...props} zIndex={1} help={help} setHelp={setHelp} />
        </Box>
    );
}

export default IMSToolbar;