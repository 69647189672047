import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import {lighten } from '@mui/material/styles';
import orderHelpers from '../../helpers/orders';
import { convertArrayToCSV } from 'convert-array-to-csv';
import Tooltip from '@mui/material/Tooltip';


function FailedTrackingTable(props) {

        const columns = [
            {
                field: 'warehouse',
                headerName: 'Warehouse',
                minWidth: 150,
                flex:1, 
                editable: false
            }, 
            {
                field: 'vendor_name',
                headerName: 'Vendor',
                minWidth: 200,
                flex:1, 
                editable: false
            }, 
            {
                field: 'internal_po_number',
                headerName: 'PO',
                renderCell: (params) => {
                    return(
                        <Tooltip title={params.row.internal_po_number}>
                            <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{params.row.internal_po_number}</Box>
                        </Tooltip>
                    )
                },
                minWidth: 150,
                flex:1, 
                editable: false
            }, 
            {
                field: 'internal_sku',
                headerName: 'SKU',
                minWidth: 150,
                flex:1, 
                editable: false
            }, 
            {
                field: 'shipment_weight',
                headerName: 'Shipment Weight',
                minWidth: 100,
                flex: 1,
                editable: false
            },
            {
                field: 'total_on_shipment',
                headerName: 'Total On Shipment',
                minWidth: 150,
                flex: 1,
                editable: false
            },
            {
                field: 'box_type',
                headerName: 'Box',
                minWidth: 50,
                flex:1, 
                editable: false
            }, 
            {
                field: 'name',
                headerName: 'Name',
                renderCell: (params) => {
                    return(
                        <Tooltip title={params.row.name}>
                            <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{params.row.name}</Box>
                        </Tooltip>
                    )
                },
                minWidth: 150,
                flex:1, 
                editable: false
            },
            {
                field: 'address1',
                headerName: 'Address',
                minWidth: 200,
                renderCell: (params) => {
                    return(
                        <Tooltip title={params.row.address1}>
                            <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{params.row.address1}</Box>
                        </Tooltip>
                    )
                },
                flex:1, 
                editable: false
            },
            {
                field: 'address2',
                headerName: 'Address2',
                minWidth: 150,
                flex:1, 
                editable: false
            },
            {
                field: 'city',
                headerName: 'City',
                flex:1, 
                minWidth: 150,
                editable: false
            },
            {
                field: 'state',
                headerName: 'State',
                minWidth: 50,
                flex:1, 
                editable: false
            }, 
            {
                field: 'zip_code',
                headerName: 'Zip',
                minWidth: 150,
                flex:1, 
                editable: false
            }, 
        ]


    

        return(
            <Box>

            <Box > 
                <Box 
                // sx={{display: 'flex', flexDirection: 'row'}}
                >
                    <Button variant='contained' onClick={props.getTracking} className={`${props.company} ${props.warehouse} secondary`}
                    // sx={{ml: props.isMobile ? '20px' : '800px', mt: '20px', maxHeight: '45px'}}
                    >
                        Refresh
                    </Button>
                </Box>
                <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                    <DataGrid
                    style={{height:"70%"}}
                        className={props.isMobile ? "mobile-table" : "desktop-table"}
                        rows={props.tracking ? props.tracking : []}
                        columns={columns}
                        rowsPerPageOption = {[25, 50, 100]}
                        checkboxSelection = {true}
                        sortModel = {[{field: 'internal_po_number', sort: 'desc'}]}
                        selectionModel={props.selected}
                        onSelectionModelChange = {(selectionModel, details) => {
                            props.handleSelect(selectionModel)
                        }}
                    />
                </Box>
            </Box>
        </Box>
        )
    }


export default FailedTrackingTable;