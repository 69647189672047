import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import {lighten } from '@mui/material/styles';

function LogTable(props) {

    
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 100,
            flex:1, 
            editable: false
        }, 
        {
            field: 'meta',
            headerName: 'Metadata',
            minWidth: 250,
            renderCell: (params) => {
                return(
                    <Tooltip title={params.row.meta}>
                        <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{params.row.meta}</Box>
                    </Tooltip>
                )
            },
            flex:1, 
            editable: false
        }, 
        {
            field: 'message',
            headerName: 'Message',
            renderCell: (params) => {
                return(
                    <Tooltip title={params.row.message}>
                        <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{params.row.message}</Box>
                    </Tooltip>
                )
            },
            minWidth: 400,
            flex:1, 
            editable: false
        }, 
        {
            field: 'timestamp',
            headerName: 'Time',
            minWidth: 200,
            valueGetter: (params) => {
                let split_one = params.row.timestamp.split("T");
                let split_two = split_one[1].split(".");
                return(`${split_one[0]} ${split_two[0]}`)
            },
            flex:1, 
            editable: false
        }, 
        {
            field: 'level',
            headerName: 'Level',
            minWidth: 100,
            flex:1, 
            editable: false
        }, 
    ];

    const getClass = (row) => {
        if(row.level === "error") return "error"
        return "default"
    }

    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'row'}}>
            <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{ height: 1000, marginTop: '50px'}} sx={{ 
                        '& .failed': {
                            bgcolor: (theme) => lighten(theme.palette.error.light, 0.5)
                        }
                        }}>
                <DataGrid
                className={props.isMobile ? "mobile-table" : "desktop-table"} 
                rows={props.log}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                getRowClassName={(params) => getClass(params.row)}
                sortModel = {[{field: 'id', sort: 'desc'}]}
                />
            </Box>            
        </Box>
    )
}

export default LogTable