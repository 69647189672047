import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PermissionsTable from './PermissionsTable';
import EditUser from './EditUser';
import userHelpers from '../../helpers/users';
import Dialog from '@mui/material/Dialog';

function Users(props) {
    
    const [userPermissions, setPermissions] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editUser, toggleEditUser] = useState(false);
    const [selected, setSelectedUser] = useState({});
    const [showEditButton, setShowEditButton] = useState(false);


    const setLoading = props.setLoading;
    const token = props.token;
    const roles = useMemo(() => ["name", "email", "furhaven", "worldwise", "kentucky", "pennsylvania", "admin", "dropship", "bulk", "inventory", "warranties"], []);

    useEffect(() => {
        const getUsers = async () => {
            setLoading(true)
            let users = await userHelpers.getUsers('Furhaven', token);
            let perms = users.map((obj, index) => {
                let out = {
                    username: obj.username,
                    name: obj.name,
                    email: obj.email,
                    id: index
                };
                for(let role of roles) {
                    if(role === "name" || role === "email") continue;
                    if(obj.groups.includes(role)) {
                        out[role] = true;
                    }
                    else {
                        out[role] = false;
                    }
                }
                return out;
            });
            setPermissions(perms);
            setLoading(false);
        }
        getUsers();
        return () => {
            setPermissions([])
        }
    }, [refresh, setLoading, token, roles])

    const selectUser = (selectedUser) => {
        if(selectedUser.length === 1)
        {
            setShowEditButton(true);
            setSelectedUser(userPermissions[selectedUser[0]]);
        } else {
            setShowEditButton(false);
        }
    }

    const updateUser = async(user, name, furhaven, worldwise, kentucky, pennsylvania, admin, dropship, bulk, inventory, warranties) => {
        let body = {
            name: name,
            permissions: {
                furhaven: furhaven,
                worldwise: worldwise,
                kentucky: kentucky,
                pennsylvania: pennsylvania,
                admin: admin,
                dropship: dropship,
                bulk: bulk,
                inventory: inventory,
                warranties: warranties
            }
        }
        await userHelpers.updateUser(props.company, user.username, body, token);
        setRefresh(!refresh);
        toggleEditUser(false);
    }

    const deleteUser = async() => {
        let user = selected;
        let check = prompt(`User ${user.name} will be deleted. Please enter '${user.name}' to confirm.`, "");
        if(check === user.name) {
            await userHelpers.deleteUser(props.company, user.username, token);
            setRefresh(!refresh);
        }
    }

        return(
            <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
                <Dialog open={editUser} onClose={()=>toggleEditUser(false)}>
                    <Box>
                        <EditUser user={selected} updateUser={updateUser} editUser={toggleEditUser} />
                    </Box>
                </Dialog>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Users</h1> 
                    <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column">
                        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex">
                            {
                                showEditButton ? 
                                    <Button style={{margin:"auto"}} variant='contained' onClick={() => toggleEditUser(true)} color="success">Edit User</Button>
                                :
                                null
                            }
                            {
                                showEditButton && !editUser ? 
                                    <Button style={{margin:"auto"}} variant='contained' color="error" onClick={() => deleteUser()}>Delete User</Button>
                                :
                                null
                            }
                        </Box>
                        <Button style={{margin: "auto", marginTop:"20px"}} variant="contained" color="success" onClick={()=>setRefresh(!refresh)}>Refresh</Button>
                        <PermissionsTable 
                            isMobile={props.isMobile}
                            users={userPermissions}
                            scopes={roles}
                            // getUsers={this.getUsers}
                            selectUser = {selectUser}
                        />
                    </Box>
                    {/* } */}
                
                </Box>
            </Box>
        )

}

export default Users;