import React, { Component} from 'react';
import Box from '@mui/material/Box';
import OrderDetailTable from './OrderDetailTable';
import PullsheetsTable from './PullsheetsTable';
import PullsheetConfig from './PullsheetConfig';
import orderHelpers from '../../helpers/orders';
import Alert from '@mui/material/Alert';
import vendorHelpers from '../../helpers/vendors';
import Unprocessed from './Unprocessed';
import fileHelpers from '../../helpers/files';
import {jsPDF} from "jspdf"
import 'jspdf-autotable';

class Pullsheets extends Component {
    
    state = {
        user: {},
        showPullsheetTable: true,
        showOrderDetail: false,
        showActionButtons: false,
        uploadId: null,
        orders: [{test:'test', id: 0}],
        orderUploads: [
            {
                id: 0,
                pull_sheet_id: '123456',
                date: '2022-03-04'
            },
            {
                id: 1,
                pull_sheet_id: '654321',
                date: '2022-03-05'
            }
        ],
        pullSheets: [],
        vendors: [],
        carrier: '',
        warehouse: '',
        type: '',
        // multiples: 0,
        // assembly: 0,
        // presses: 0,
        selected: []
    }

    componentDidMount(){
        const user = this.props.user;
        this.setState({user}, () => {
            this.getPullSheets();
            this.getUngroupedOrders();
        })
    }

    handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        this.setState({
            [name]: value
        })
    }

    getOrders = async () => {
        this.props.setLoading(true);
        let res = await orderHelpers.getOrders(this.props.company, {pullsheet_id: this.state.selected[0]}, this.props.token)
        try {
            let found = [];
            let orders = res.filter(obj => {
                if(!found.includes(obj.order_id)) {
                    found.push(obj.order_id);
                    return obj;
                }
                return null;
            })
            this.setState({ orders: orders})
        } 
        catch(err) {
            this.setState({error: `Failed to get orders: ${err.message}`})
        }
        this.props.setLoading(false);
    }

    updateSelected = async(newSelect) => {
        this.setState({selected: newSelect.filter(obj => !this.state.selected.includes(obj))})
    }

    getPullSheets = async () => {
        this.props.setLoading(true);
        this.setState({loading: true, error: false, error_message: ""})
        try {
            let params = {};
            params.warehouse = this.props.warehouse;
            let res = await orderHelpers.getPullSheets(this.props.company, params, this.props.token);
            let pullsheets = res.pullsheets;
            console.log("", pullsheets)
            if(!pullsheets) throw new Error("No Pullsheets Found")
            pullsheets = pullsheets.sort((a,b) => {
                return a.sort - b.sort;
            });
            this.setState({ pullSheets: pullsheets, loading: false })

        }
        catch(err) {
            this.setState({error: true, error_message: `Failed to get Pullsheets: ${err.message}`, loading: false, pullSheets: []});
        }
        finally {
            this.props.setLoading(false);
        }      
    }

    getVendors = async() => {
        let vendors = await vendorHelpers.getVendors(this.props.company, this.props.token)
        this.setState({vendors: vendors.vendors})
    }
    
    showOrders = (uploadId) => {
        this.setState({
            showOrderDetail: true,
            showPullsheetTable: false,
            showPullsheetConfig: false,
            uploadId
        })
    }

    showPullsheetTable = () => {
        this.setState({
            showOrderDetail: false,
            showPullsheetTable: true,
            showActionButtons: false,
            showPullsheetConfig: false
        })
    }

    showPullsheetConfig = () => {
        this.setState({
            showOrderDetail: false,
            showPullsheetTable: false,
            showActionButtons:false,
            showPullsheetConfig: true
        })
    }

    makeCSV = function(orders) {
        return new Promise((resolve, reject) => {
            let rows = [["Order ID", "Vendor", "Upload Date", "Order Date", "Internal PO", "External PO", "SKU", "QTY", "Name", "Address", "Address 2", "City", "State", "Zip", "Carrier", "Tracking Number", "Order Status", "Tracking Status", "Vendor Status", "Acumatica Status", "Acumatica Order", "Acumatica Shipment", "Tote ID", "Truck Number"]]
            for(let order of orders) {
                let row = [
                    order.order_id,
                    order.vendor_name,
                    order.upload_date,
                    order.order_date,
                    order.internal_po_number,
                    order.external_po_number,
                    order.internal_sku,
                    order.quantity,
                    order.name,
                    order.address1,
                    order.address2 || "",
                    order.city,
                    order.state,
                    order.zip_code,
                    order.carrier || "",
                    order.tracking_number || "",
                    order.order_status,
                    order.tracking_status || "",
                    order.vendor_status || "",
                    order.acumatica_status || "",
                    order.acu_order_num || "",
                    order.acu_ship_num || "",
                    order.tote_id || "",
                    order.truck_number || ""
                ];
                rows.push(row);
            }
            let csv = rows.map(row => row.map(String).map(obj => obj.replaceAll('"', '""')).map(obj=> `"${obj}"`).join(',')).join("\r\n");
            let file = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            let a = document.createElement("a");
            let url = URL.createObjectURL(file);
            a.href = url;
            a.download = `${this.state.selected[0]}_Carrier_Report.csv`;
            a.click();
            resolve()
        })
    }

    
    getCarrierReport = async() => {
        this.props.setLoading(true);
        let res = await orderHelpers.getOrders(this.props.company, {pullsheet_id: this.state.selected[0]}, this.props.token)
        try{
            await this.makeCSV(res)

        } 
        catch(err) {
            this.setState({error: `Failed to generate carrier report: ${err.message}`})
        }
        this.props.setLoading(false);
    }

    

    getShippingDocs = async () => {
        this.props.setLoading(true);
        try {
            let labelLinks = await orderHelpers.getLabelsDownloadLink(this.props.company,  {pullsheet_id: this.state.selected[0]}, this.props.token);
            let uri = await fileHelpers.mergePDFs(labelLinks);
            await fileHelpers.downloadFile(uri, `labels_pullsheet_${this.state.selected[0]}`);
        }
        catch(err) {
            console.log(err);
        }
        finally {
            this.props.setLoading(false);
        }
        // this.downloadFile(labelLink, `labels_pullsheet_${this.state.selected[0]}`);
    }
    regenPackslips = async() => {
        this.props.setLoading(true);
        await orderHelpers.regenPackslips(this.props.company, {pullsheet_id: this.state.selected[0]}, this.props.token);
        this.props.setLoading(false);
    }

    createPullsheet = async () => {
        console.log('creating pull sheet');
        this.props.setLoading(true);
        let warehouse;
        if(this.state.warehouse){
            warehouse = this.state.warehouse
        } else {
            warehouse = this.props.warehouse
        }

        let options = {
            warehouse: warehouse,
            carrier: this.state.carrier,
            assembly: this.state.type === "assembly" ? 1 : 0,
            presses: this.state.type === "presses" ? 1 : 0,
            multiples: this.state.type === "multiples" ? 1 : 0
        }
        let create = await orderHelpers.createPullSheet(this.props.company, options, this.props.token)
        if(create !== true){
            this.setState({error: true, error_message: create})
        } else {
            this.showPullsheetTable()
        }
        this.props.setLoading(false);
    }

    getUngroupedOrders = async () => {
        let res = await orderHelpers.getUngroupedOrders(this.props.company, this.props.token);

        if(res.success){
            this.setState({
                counts: res.data
            })
        } else {
            this.setState({error: true, error_message: res.error})
        }
    }

    refresh = async() => {
        await this.getPullSheets();
        await this.getUngroupedOrders();
    }

    downloadPullsheet = async() => {
        this.props.setLoading(true);
        let res = await orderHelpers.getOrders(this.props.company, {pullsheet_id: this.state.selected[0]}, this.props.token)
        try {
            let sheet = this.state.pullSheets.filter((obj) => obj.pullsheet_id === this.state.selected[0])[0];
            let type = sheet.type;
            await this.downloadPullsheetHelper(res, this.state.selected[0], type)

        }
        catch(err) {
            this.setState({error: `Failed to generate carrier report: ${err.message}`})
        }
        this.props.setLoading(false);
    }

    downloadPullsheetHelper = async(pullsheet_data, pullsheet_id, type) => {
        let warehouse = pullsheet_data[0].warehouse;
        const JsBarcode = require ('jsbarcode');
        let barcode = document.createElement("img");
        let initial = 0;
        let qtys = pullsheet_data.map(obj => obj.quantity)
        let total = qtys.reduce((a, b) => a + b, initial)
        let loc = "";
        let code = "";
        if(warehouse === "Kentucky") {
            loc = "ky_location";
            code = "KY";
        }
        else {
            loc = "pa_location";
            code = "PA";
        }
        switch(type.toLowerCase()) {
            case "non_assembly":
                code += "2";
                break;
            case "multiples":
                code += "3";
                break;
            case "presses":
                code += "4";
                break;      
            default:
                break;      
        }

        let quantities = pullsheet_data.reduce((prev, curr) => {
            if(!(curr.internal_sku in prev)) {
                prev[curr.internal_sku] = {
                    quantity: 0,
                    description: curr.description,
                    loc: curr[loc],
                    sort: curr.sort
                };
            }
            prev[curr.internal_sku].quantity += curr.quantity;
            return prev;
        }, {})

        let items = [];
        for(let sku in quantities) {
            let item = quantities[sku];
            items.push([sku, item.quantity, item.description, item.loc])
        }
        items.sort((a, b) => {
            let a_loc = a[3].split("-");
            let b_loc = b[3].split("-");
            if(a_loc.length < 2) return 1;
            if(b_loc.length < 2) return -1;
            if(Number(a_loc[0]) === Number(b_loc[0])) {
                return Number(a_loc[1]) - Number(b_loc[1]);
            }
            else {
                return Number(a_loc[0]) - Number(b_loc[0]);
            }
        })
        JsBarcode(barcode, "*" + pullsheet_id);
        await this.makePullsheetPDF(pullsheet_id, barcode, code, items, total);
    }



    makePullsheetPDF = async(pullsheet_id, barcode, code, items, total) => {
        let doc = new jsPDF();
        doc.addImage(barcode.src, 15, 15);
        doc.setFontSize(12);
        let now = new Date();
        let date = now.toISOString().slice(0,10)
        doc.text(180, 15, date);
        doc.text(15, 15, code)
        doc.setFontSize(16);
        if(code.includes("2")) {
            doc.text(140, 59, "Non-Assembly");
        }
        else if(code.includes("3")) {
            doc.text(140, 59, "Multiples");
        }
        else if(code.includes("4")) {
            doc.text(140, 59, "Presses");
        }
        else {
            doc.text(140, 59, "General Assembly");
        }
        doc.text(13, 67, "Total Pieces: " + String(total));
        doc.autoTable({
            head: [["SKU", "Quantity", "Item", "Location"]],
            body: items,
            theme: "striped",
            columnStyles: {
                3: {cellWidth: 'auto'}
            },
            startY: 69,
            margin: {top: 15, bottom: 15, left: 15, right: 15},
            headStyles: {
                fillColor: "#187947",
                textColor: "white",
                halign: "center",
                valign: "middle"
            }
        });
        doc.save("pullsheet_" + pullsheet_id + "_" + date + ".pdf");
    }



    render(){
        return(
            <Box display="flex" className={this.props.isMobile ? "mobile-top" : "desktop-top"} flexDirection="column">
                <h1>Pull Sheets</h1>
                <Box display="flex" flexDirection="column">
                        <Unprocessed counts={this.state.counts} {...this.props} />
                        
                        {
                            this.state.showOrderDetail ? 
                                <OrderDetailTable 
                                    isMobile = {this.props.isMobile}
                                    getOrders = {this.getOrders}
                                    orders = {this.state.orders}
                                    showPullsheetTable = {this.showPullsheetTable}
                                /> : null
                        }
                    </Box>
                    <Box className={this.props.isMobile ? "mobile-box" : "desktop-box"}  display="flex" flexDirection="column">
                        {this.state.error ? <Alert severity="error">{this.state.error_message}</Alert> : null}
                        {
                            this.state.showPullsheetTable ? 
                                <PullsheetsTable 
                                    refresh = {this.refresh}
                                    warehouse = {this.props.warehouse}
                                    company = {this.props.company}
                                    isMobile = {this.props.isMobile}
                                    selected = {this.state.selected}
                                    updateSelected = {this.updateSelected}
                                    downloadPullsheet = {this.downloadPullsheet}
                                    pullSheets = {this.state.pullSheets}
                                    vendors = {this.state.vendors}
                                    getVendors = {this.getVendors}
                                    showOrders = {this.showOrders}
                                    getPullSheets = {this.getPullSheets}
                                    getCarrierReport = {this.getCarrierReport}
                                    getShippingDocs = {this.getShippingDocs}
                                    showPullsheetConfig = {this.showPullsheetConfig}
                                    regenPackslips = {this.regenPackslips}
                                    loading = {this.state.loading}
                                /> : null
                        }
                        {
                            this.state.showPullsheetConfig ? 
                            <PullsheetConfig 
                                type={this.state.type}
                                handleChange={this.handleChange}
                                carrier={this.state.carrier}
                                selectedWh={this.state.warehouse}
                                warehouse={this.props.warehouse}
                                warehouses={this.props.warehouses}
                                admin={this.state.user.admin}
                                showPullsheetTable={this.showPullsheetTable}
                                createPullsheet={this.createPullsheet}
                            /> : null
                        }
                    </Box>

                    
            </Box>
            
        )
    }
}

export default Pullsheets;