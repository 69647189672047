import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    {
        field: 'action_id',
        headerName: 'ID',
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: 'action',
        headerName: 'Type',
        // width: 75, 
        flex: 1,
        editable: false,
        minWidth: 150
    }, 
    {
        field: 'user',
        headerName: 'User',
        // width: 125, 
        flex: 1,
        editable: false,
        minWidth: 100
    }, 
    {
        field: 'date',
        headerName: 'Date',
        // width: 125,
        flex: 1, 
        editable: false,
        minWidth: 200
    },
    {
        field: 'successful',
        headerName: 'Success',
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: 'failed',
        headerName: 'Failed',
        flex: 1,
        editable: false,
        minWidth: 100
    },
    {
        field: 'status',
        headerName: 'Status',
        // width: 250, 
        flex: 1,
        valueGetter: (params) => {
            if(params.row.status === "action_complete") return "Complete";
            if(params.row.status === "action_failed") return "Failed";
            if(params.row.status === "action_processing") return "Processing";
        },
        editable: false,
        minWidth: 100
    } 
]

class StaticStatusTable extends Component{

    componentDidMount(){
        this.props.getStaticUpdates();
    }

    render(){
        return(
            <Box className={this.props.isMobile ? "mobile-box" : "desktop-box"} sx={{display: 'flex', flexDirection: 'column', mt:'25px'}}>

            <Box className={this.props.isMobile ? "mobile-box" : "desktop-box"}> 
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <h3>Update Status</h3>
                    <Button variant='contained' onClick={this.props.getStaticUpdates} color="success" sx={{maxHeight: '45px', m:"auto"}}>Refresh</Button>
                </Box>
                <Box className={this.props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                    <DataGrid
                        className={this.props.isMobile ? "mobile-table" : "desktop-table"}
                        rows={this.props.updates}
                        columns={columns}
                        rowsPerPageOption = {[25, 50, 100]}
                        checkboxSelection = {false}
                        sortModel = {[{field: 'action_id', sort: 'desc'}]}
                    />
                </Box>
            </Box>
        </Box>
        )
    }
}

export default StaticStatusTable;