import axios from 'axios';
import getJWT from './token';
import Papa from 'papaparse';

let baseApi = `https://api.${process.env.REACT_APP_BASE_URL || "mattk.commerce-ims.com"}`;

function formatFile(file) {
    return new Promise((resolve, reject) => {
        if(path.extname(file.name) === ".csv") {
            Papa.parse(file, {worker: true, header: true, complete: function(csv) {
                resolve(csv.data);
            }})
        }
        else {
            console.log("Failed to format file.");
            reject();
        }
    });
}
var path = require('path');

const bulkHelpers = {
    downloadTemplate: async function(company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/labels/template"
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let buffer = Buffer.from(result.data.Body);
            let blob = new Blob([buffer], {type: "text/csv"})
            const a = document.createElement("a");
            a.download = "BoxLabelTemplate.csv";
            a.href = window.URL.createObjectURL(blob);
            a.click();
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    downloadList: async function(setFiles, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/labels"
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let data = result.data.Contents.map(function(obj, index) {return {key: index, name: obj.Key, date: obj.LastModified}}).filter(obj => obj.name.indexOf("TEMP") === -1)
            data.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
            setFiles(data);
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    downloadFile: async function(fileName, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/labels/download?key=" + fileName
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let body = result.data;
            const a = document.createElement("a");
            a.download = fileName;
            a.target = "_blank"
            a.href = body;
            a.click();
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    downloadData: async function(fileNameRaw, company_id, token) {
        const userToken = await getJWT(token);
        let fileName = fileNameRaw.slice(fileNameRaw.indexOf("/") + 1, -4);
        let url = baseApi + "/bulk/labels/data?filename=" + fileName;
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let body = result.data;
            body.unshift({date:"date",gsin:"gsin",asin:"asin",units:"units",cartons:"cartons",po_number:"po_number"});            
            let out = body.map(obj => `${obj.date}, ${obj.gsin}, ${obj.asin}, ${obj.units}, ${obj.cartons}, ${obj.po_number}`).join('\r\n');
            const a = document.createElement("a");
            a.download = fileName + ".csv";
            a.target = "_blank"
            a.href = "data:attachment/csv," + out;
            a.click();
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    submitFile: async function(file, changeFile, company_id, user, token) {
        const userToken = await getJWT(token);
        let promise = formatFile(file);
        promise.then(function(result) {
            changeFile(null);
            axios.post(baseApi + '/bulk/labels/create', {"data": result, "user":user}, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((response) => {
                console.log("File Submitted");
            });
        });
    }

}

export default bulkHelpers;