import React from "react";
import Box from '@mui/material/Box';
import UploadForm from './UploadForm';
import LabelList from './LabelList';
import Button from '@mui/material/Button';
import bulkHelpers from "../../helpers/bulk";

function BoxLabels(props) {

    return (
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{ display: 'flex', flexDirection: 'column'}}>
                <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Box Labels</h1> 
                    <Button style={{width:props.isMobile ? "200px" : "50%", marginBottom:"10px", marginTop:"10px"}} onClick={() => bulkHelpers.downloadTemplate(props.company + "_bulk", props.token)}>Download Template</Button>
                    <UploadForm {...props} />
                    <LabelList {...props} />
                </Box>
            </Box>
    )
}

export default BoxLabels;