import React from 'react';
import Box from '@mui/material/Box';
import ApiStatusTable from './ApiStatusTable';

function ApiStatus(props) {




    return(
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"} display="flex" flexDirection="column">
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"} >
                <h1>API Status</h1> 
                <ApiStatusTable isMobile={props.isMobile} setLoading={props.setLoading} warehouse={props.warehouse} company={props.company} token={props.token} />
            </Box>
        </Box>
    )
}

export default ApiStatus;