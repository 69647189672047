import {React} from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import HelpButton from './HelpButton'

function User(props) {

    const user = <Typography variant="h6" component="div" style={{marginLeft:"auto",marginTop:"auto",marginBottom:"auto",marginRight:"auto"}}>
                    {props.user.attributes?.name || ""}
                </Typography>

    return(
        props.isMobile ? 
        <Box display="flex" style={{marginLeft:"auto"}}>
            {user}
        </Box>
        : 
        <Box display="flex" style={{marginLeft:"auto"}}>
            <Divider style={{height: "50px", marginLeft:"10px"}} orientation="vertical" flexItem />
            <HelpButton {...props} />
            <Divider style={{height: "50px", marginRight:"10px"}} orientation="vertical" flexItem />
            {user}
        </Box> 
    )
}

export default User;