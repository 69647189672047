import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UploadsTable from './UploadsTable';
import { CSVReader } from 'react-papaparse';
import orderHelper from '../../helpers/orders';
import orderHelpers from '../../helpers/orders';
import Alert from '@mui/material/Alert';
import fileHelpers from '../../helpers/files';

const buttonRef = React.createRef();

function FileUpload(props) {

  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [orders, setOrders] = useState(null);
  const [uploads, setUploads] = useState([]);
  const [selected, setSelected] = useState([]);

    const handleOpenDialog = (e) => {
      // Note that the ref is set async, so it might be null at some point
      if (buttonRef.current) {
        buttonRef.current.open(e);
      }
    };

    const handleOnFileLoad = (data, file) => {
      console.log('---------------------------');
      setFile(file);
      setSuccess(false);
      const orders = orderHelper.formatOrders(data);
      setOrders(orders);
      console.log('---------------------------');
    };
  
    const handleOnError = (err, file, inputElem, reason) => {
      console.log('---------------------------');
      console.log(err);
      console.log('---------------------------');
      setError(`Error Uploading Order File: ${file}`)
    };

    const getTracking = async (upload_id) => {
      console.log('getting tracking');
      setError(""); 
      props.setLoading(true);
      let res = await orderHelpers.getTracking(props.company, {upload_id: upload_id}, props.token);
      let tracking = res.tracking;
      if(!tracking) {
          setError("Failed to get tracking."); 
          props.setLoading(false);
          return; 
      }
      let vendor = res.vendor;
      console.log("TRACKING", tracking);
      let csv = tracking.map(obj => obj.join(",")).join("\n");
      let blob = new Blob([csv], {type: "text/csv"})
      let uri = window.URL.createObjectURL(blob);
      console.log("URI", uri)
      let a = document.createElement("a");
      a.setAttribute("href", uri);
      a.setAttribute("download", vendor + "_" + upload_id + "_tracking.csv");
      document.body.appendChild(a);
      a.click();
      // if(tracking.length > 1) {
      //     let headers = tracking.shift();
      //     // let tracking_csv = tracking.map(function(obj) {
      //     //     let out = {};
      //     //     for(let i in headers) {
      //     //         out[headers[i]] = obj[i]
      //     //     }
      //     //     return out;
      //     // })
      //     console.log("TRACKING_CSV", tracking);
      //     let csv = convertArrayToCSV(tracking, {header:headers});
      //     console.log("CSV", csv);
      //     let uri = encodeURI("data:text/csv;charset=utf-8," + csv);
      //     console.log("URI", uri);
      //     let a = document.createElement("a");
      //     a.setAttribute("href", uri);
      //     a.setAttribute("download", vendor + "_" + upload_id + "_tracking.csv");
      //     document.body.appendChild(a);
      //     a.click();
      // }
      // else if(tracking.length === 1) {
      //     let csv = convertArrayToCSV(tracking);
      //     let uri = encodeURI("data:text/csv;charset=utf-8," + csv);
      //     let a = document.createElement("a");
      //     a.setAttribute("href", uri);
      //     a.setAttribute("download", vendor + "_" + upload_id + "_tracking.csv");
      //     document.body.appendChild(a);
      //     a.click();
      // }
      props.setLoading(false);
  }
  


    const uploadFile = async () => {
      props.setLoading(true);
      setError("")
      setSuccess(false)
      const body = {
        orders: orders,
        user: props.user.attributes.name, 
        company_name: props.company,
        filename: file.name
      }
      let upload = await orderHelpers.uploadOrders(props.company, body, props.token);
      if(upload === true) {
        setSuccess(true);
        handleRemoveFile()
        setTimeout(() => {
          getUploads()
        }, 5000)
      } else {
        setError(`Error Uploading Order File: ${upload}`);
      }
      props.setLoading(false);
    }
    
  
    const handleRemoveFile = (e) => {
      // Note that the ref is set async, so it might be null at some point
      if (buttonRef.current) {
        buttonRef.current.removeFile(e);
        setFile(null);
        setOrders(null);
      }
    };

    const deleteUpload = async() => {
      if(window.confirm(`Delete Upload #${selected[0]}?`)) {
        props.setLoading(true);
        await orderHelpers.deleteUpload(props.company, {upload_id:selected[0]}, props.token)
        props.setLoading(false);
      }
    }

    const handleSelect = (e) => {
      setSelected(e)
    }

    const getUploads = async () => {
      props.setLoading(true);
      console.log('getting uploads')
      let uploads = await orderHelpers.getUploads(props.company, {}, props.token)
      setUploads(uploads);
      props.setLoading(false);
    }

    const redoLabels = async() => {
      if(window.confirm(`Redo Labels for Upload #${selected[0]}?`)) {
        props.setLoading(true);
        await orderHelpers.redoTracking(props.company, {upload_id:selected[0]}, props.token)
        props.setLoading(false);
      }
    }

    const downloadMaster = async() => {
      props.setLoading(true);
      let packslips = await orderHelpers.getMasterPackslips(props.company, {upload_id:selected[0]}, props.token)
      let uri = await fileHelpers.mergeUnsortedPDFs(packslips);
      await fileHelpers.downloadFile(uri, `MasterPackingSlips-${selected[0]}.pdf`);
      props.setLoading(false);
    }


        return(
            <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                {error !== "" ? <Alert style={{marginTop:"24px", color:"white", width:"80%"}} severity="error" className={`${props.company} ${props.warehouse} error`}>{error}</Alert> : null}
                  <h1>File Uploads</h1> 
                    <CSVReader
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        noDrag
                        config={{
                          header: true,
                          skipEmptyLines: true
                        }}
                        style={{}}
                        >
                        {({ file_obj }) => (
                          <aside>
                            <Button variant='contained' onClick={handleOpenDialog} className={`${props.company} ${props.warehouse} secondary`}
                            // sx={{mr: '10px', mb: '10px'}}
                            >Browse file</Button>
                            <div>
                              {file_obj && file_obj.name}
                            </div>
                            {file !== null ? 
                              <Box 
                              // sx={{flexDirection: 'row', mt: '10px'}}
                              >
                                <Button variant='contained' onClick={uploadFile} className={`${props.company} ${props.warehouse} secondary`}
                                // sx={{mr: '10px'}}
                                >Upload</Button>
                                <Button variant='contained' sx={{ml:"30px"}} onClick={handleRemoveFile} className={`${props.company} ${props.warehouse} tertiary`}>Remove</Button>
                              </Box>
                            : null}
                          </aside>
                        )}
                      </CSVReader>
                  {success ? <Alert severity="success">Upload Successful!</Alert> : null}

                  {selected.length === 1 ? 
                    <Box style={{marginTop:"50px", border: "1px solid black", width:"60%", padding:"20px", paddingTop:"0"}}>
                      <h3>Upload ID: {selected[0]}</h3>
                      <Box display="flex">
                        <Button color="primary" variant="contained" onClick={() => {getTracking(selected[0])}}>Tracking</Button>
                        {props.scopes?.includes("admin") ? <Button style={{marginLeft:"20px"}} onClick={deleteUpload} color="error" variant="contained">Delete</Button> : null}
                        {props.scopes?.includes("admin") ? <Button style={{marginLeft:"20px"}} onClick={redoLabels} color="secondary" variant="contained">Re-create Labels</Button> : null}
                        {uploads.filter(obj=>obj.upload_id===selected[0])[0]?.vendor_name === "Petsense" ? <Button style={{marginLeft:"20px"}} onClick={downloadMaster} variant="contained" color="info">Get Master Packslips</Button> : null}
                      </Box>

                    </Box>
                    : null}
                  <UploadsTable 
                    uploads={uploads}
                    getUploads={getUploads}
                    company={props.company}
                    warehouse={props.warehouse}
                    token={props.token}
                    isMobile={props.isMobile}
                    setLoading={props.setLoading}
                    setError={setError}
                    handleSelect={handleSelect}
                    selected={selected}
                  />
                </Box>
            </Box>
        )
    
}

export default FileUpload