import {React, useEffect} from 'react';
import {Authenticator, useAuthenticator, View, ThemeProvider, useTheme} from '@aws-amplify/ui-react';


import '@aws-amplify/ui-react/styles.css';
import {useNavigate, useLocation} from 'react-router';


function Login() {
    const {route} = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();

    const {tokens} = useTheme();
    const theme = {
        name: "Furhaven",
        tokens: {
            colors: {
              background: {
                primary: {
                  value: tokens.colors.white.value,
                },
                secondary: {
                  value: tokens.colors.neutral['40'].value,
                },
              },
              font: {
                interactive: {
                  value: tokens.colors.green['80'].value,
                },
              },
              brand: {
                primary: {
                  '10': tokens.colors.green['40'],
                  '80': tokens.colors.green['60'],
                  '90': tokens.colors.green['80'],
                  '100': tokens.colors.green['100'],
                },
              },
            },
            components: {
              tabs: {
                item: {
                  _focus: {
                    color: {
                      value: tokens.colors.green['80'].value,
                    },
                  },
                  _hover: {
                    color: {
                      value: tokens.colors.green['80'].value,
                    },
                  },
                  _active: {
                    color: {
                      value: tokens.colors.green['80'].value,
                    },
                    backgroundColor: {
                        value: tokens.colors.white.value
                    },
                    borderColor: {
                        value: tokens.colors.green['80'].value
                    }
                  },
                },
              },
            },
          },
        };


    let from = location.state?.from?.pathname || '/';
    useEffect(() => {
        if(route === 'authenticated') {
            navigate(from, {replace: true});
        }
    }, [route, navigate, from])
    

    return (
            <ThemeProvider theme={theme}>
                <View style={{marginTop:"50px"}} className="auth-wrapper">
                    <Authenticator />
                </View>
            </ThemeProvider>
    );
}

export default Login;