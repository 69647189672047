import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LogTable from './LogTable';
import logHelpers from '../../helpers/log';

function Log(props) {
    
    const [refresh, setRefresh] = useState(false);
    const [log, setLog] = useState([]);

    const setLoading = props.setLoading;
    const company = props.company;
    const token = props.token;

    useEffect(() => {
        const getLog = async() => {
            setLoading(true);
            let new_log = await logHelpers.getLog(company, token);
            setLog(new_log);
            setLoading(false);
        }
        getLog();
    }, [refresh, company, token, setLoading])

        return(
            <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
                <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Log</h1> 
                    <Box className={props.isMobile ? "mobile-box" : "desktop-box"} display="flex" flexDirection="column">
                        <Button style={{margin: "auto", marginTop:"20px"}} variant="contained" color="success" onClick={()=>setRefresh(!refresh)}>Refresh</Button>
                        <LogTable 
                            isMobile={props.isMobile}
                            log={log}
                        />
                    </Box>                
                </Box>
            </Box>
        )

}

export default Log;