import React, { useState} from 'react';
import { BrowserRouter, Route, Navigate, Routes} from 'react-router-dom';
import FileUpload from './components/FileUpload/FileUpload';
import ApiStatus from './components/ApiStatus/ApiStatus';
import Dashboard from './components/Dashboard/Dashboard';
import Inventory from './components/Inventory/Inventory';
import Pullsheets from './components/Pullsheets/Pullsheets';
import BoxLabels from './components/BoxLabels/BoxLabels'
import BulkOrders from './components/BulkOrders/BulkOrders';
import BulkStatus from './components/BulkStatus/BulkStatus';
import FailedTracking from './components/FailedTracking/FailedTracking';
import Autobuild from './components/Autobuild/Autobuild';
import OrderSearch from './components/OrderSearch/OrderSearch';
import Users from './components/Users/Users';
import Log from './components/Log/Log';
import BugReport from './components/BugReport';
import Login from './components/Login';
import RequireAuth from './components/RequireAuth';
import {Amplify, Hub, Logger} from 'aws-amplify';
import {Authenticator} from '@aws-amplify/ui-react';
import {useMediaQuery} from 'react-responsive';
import '@aws-amplify/ui-react/styles.css';
import './css/App.css';

import awsExports from './aws-exports';

Amplify.configure(awsExports);

const logger = new Logger('My-Logger');

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            logger.info('user signed in');
            break;
        case 'signUp':
            logger.info('user signed up');
            break;
        case 'signOut':
            logger.info('user signed out');
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':
            logger.info('the Auth module is configured');
            break;
        default:
          return;
    }
}

Hub.listen('auth', listener);

function App() {

  const isMobile = useMediaQuery({query: '(max-width: 1224px)'});

  const [current_company, setCurrentCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [warehouses, setWarehouses] = useState({});
  const [current_warehouse, setCurrentWarehouse] = useState("");
  const [token, setToken] = useState("");
  const [user, setUser] = useState({});
  const [isLoading, setLoading] = useState(false);

  const config = {
      furhaven: ["kentucky", "pennsylvania"],
      worldwise: ["kentucky"]
  }

  function checkScopes(permission = "") {
    return scopes.includes(permission) || scopes.includes("admin");
  }

  function clearUser() {
    setCurrentCompany("");
    setCompanies([])
    setWarehouses({});
    setCurrentWarehouse("");
    setScopes([]);
    setToken("");
    setUser({})
    console.log("User Cleared.");
  }

    return (
      <Authenticator.Provider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route path = '/' element={
              <RequireAuth config={config} isMobile={isMobile} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                <Dashboard />
              </RequireAuth> } />

            <Route path = '/uploads' element={
              <RequireAuth config={config} isMobile={isMobile} route={"uploads"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <FileUpload /> : <Navigate to='/' replace />
                }
              </RequireAuth>
              } />

            <Route path = '/api_status' element={
              <RequireAuth config={config} isMobile={isMobile} route={"api_status"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <ApiStatus /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />


            <Route path = '/failed_tracking' element={            
              <RequireAuth config={config} isMobile={isMobile} route={"failed_tracking"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <FailedTracking /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/pullsheets' element={
              <RequireAuth config={config} isMobile={isMobile} route={"pullsheets"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <Pullsheets /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/box_labels' element={
              <RequireAuth config={config} isMobile={isMobile} route={"box_labels"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {scopes.includes('bulk') ?
                  <BoxLabels /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/order_search' element={            
              <RequireAuth config={config} isMobile={isMobile} route={"order_search"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('dropship') ? 
                  <OrderSearch /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/bulk_orders' element={            
              <RequireAuth config={config} isMobile={isMobile} route={"bulk_orders"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ? 
                  <BulkOrders /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/bulk_status' element={            
              <RequireAuth config={config} isMobile={isMobile} route={"bulk_status"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('bulk') ? 
                  <BulkStatus /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/inventory' element={
              <RequireAuth config={config} isMobile={isMobile} route={"inventory"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('inventory') ?
                  <Inventory /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/autobuild' element={
              <RequireAuth config={config} isMobile={isMobile} route={"users"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes('acumatica') ?
                  <Autobuild /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/users' element={
              <RequireAuth config={config} isMobile={isMobile} route={"users"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes() ?
                  <Users /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

            <Route path = '/log' element={
              <RequireAuth config={config} isMobile={isMobile} route={"log"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                {checkScopes() ?
                  <Log /> : <Navigate to='/'  replace /> 
                }
              </RequireAuth>
            } />

          <Route path = '/bugreport' element={
              <RequireAuth config={config} isMobile={isMobile} route={"bugreport"} setLoading={setLoading} isLoading={isLoading} clearUser={clearUser} companies={companies} warehouse={current_warehouse} setCompanies={setCompanies} warehouses={warehouses} setCurrentWarehouse={setCurrentWarehouse} setWarehouses={setWarehouses} company={current_company} setCurrentCompany={setCurrentCompany} scopes={scopes} setScopes={setScopes} token={token} setToken={setToken} user={user} setUser={setUser}>
                  <BugReport /> 
              </RequireAuth>
            } />
          </Routes>
        </BrowserRouter>
      </Authenticator.Provider>
    )
}

export default App;
