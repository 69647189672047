import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function OrderCount(props) {


        return(
            <Card sx={{maxWidth: 200, mr: '10px'}}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {props.type}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {props.count}
                    </Typography>
                </CardContent>
            </Card>
        )

}

export default OrderCount;