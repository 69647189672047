import React, { Component } from 'react';
import Box from '@mui/material/Box';
import OrderQueryForm from './OrderQueryForm'
import OrderSearchTable from './OrderSearchTable';
import orderHelpers from '../../helpers/orders';
import vendorHelpers from '../../helpers/vendors';
import OrderDetails from './OrderDetails';


class OrderSearch extends Component {

    state = {
        vendorList: [],
        start_date: {}, 
        end_date: {}, 
        vendor: '',
        upload_id: '',
        pullsheet_id: '',
        internal_po_number: '',
        external_po_number: '',
        tracking_number: '',
        name: '',
        sku: '',
        openDetails: {},
        orders: [],
        detailsTab: 0,
        direction: "right",
        order_status: 0,
        acumatica_status: 0,
        tracking_status: 0,
        warehouse: ""
    }

    componentDidMount(){
        const user = this.props.user;
        
        this.setState({user});
        this.getVendors();
    }

    getVendors = async() => {
        let vendorList = await vendorHelpers.getVendors(this.props.company, this.props.token)
        this.setState({vendorList: vendorList.vendors})
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleDateChange = (date, field) => {
        if(date !== null){
            let year = date.getFullYear();
            let month = date.getMonth()+1;
            if(month < 10){
                month = `0${month}`
            }
            let day = date.getDate();
            if(day < 10) {
                day = `0${day}`
            }
            this.setState({
                [field]: {formatted: `${year}-${month}-${day}`, full: date}
            })
        } else {
            this.setState({
                [field]: date
            })
        }
    }

    formatOrders = async(orders) => {
        let pos = {};
        for(let order of orders) {
            let po = order.internal_po_number;
            if(!(po in pos)) {
                pos[po] = {
                    acu_order_num: order.acu_order_num,
                    address1: order.address1,
                    address2: order.address2,
                    city: order.city,
                    external_po_number: order.external_po_number,
                    internal_po_number: po,
                    name: order.name,
                    order_date: order.order_date,
                    order_status: order.order_status,
                    state: order.state,
                    upload_date: order.upload_date,
                    vendor_name: order.vendor_name,
                    zip_code: order.zip_code,
                    shipments: {},
                    items: []
                }
            }
            let tn = order.tracking_number;
            if(!(order.tracking_number in pos[po].shipments)) {
                pos[po].shipments[tn] = {
                    acu_ship_num: order.acu_ship_num,
                    acumatica_status: order.acumatica_status,
                    carrier: order.carrier,
                    method: order.method,
                    date: order.upload_date,
                    truck_number: order.truck_number,
                    tracking_number: tn,
                    tracking_status: order.tracking_status,
                    tote_number: order.tote_number,
                    vendor_status: order.vendor_status,
                    warehouse: order.warehouse,
                    total_on_shipment: order.total_on_shipment,
                    items: {}
                }
            }
            let sku = order.internal_sku;
            if(!(sku in pos[po].shipments[tn].items)) {
                pos[po].shipments[tn].items[sku] = {
                    external_sku: order.external_sku,
                    internal_sku: sku,
                    quantity: 0
                }
            }
            pos[po].items.push({order_id:order.order_id, description:order.description, internal_sku: sku})
            pos[po].shipments[tn].items[sku].quantity += order.quantity;
        }
        let out = [];
        let id = 1;
        for(let po in pos) {
            let shipments = [];
            for(let tn in pos[po].shipments) {
                let items = [];
                for(let sku in pos[po].shipments[tn].items) {
                    items.push(pos[po].shipments[tn].items[sku]);
                }
                pos[po].shipments[tn].items = items;
                shipments.push(pos[po].shipments[tn]);
            }
            pos[po].shipments = shipments;
            pos[po].id = id;
            out.push(pos[po]);
            id++;
        }
        return out;
    }

    getOrders = async () => {
        this.props.setLoading(true);
        let params = {
            start_date: this.state.start_date !== null ? this.state.start_date.formatted : null, 
            end_date: this.state.end_date !== null ? this.state.end_date.formatted : null, 
            vendor_name: this.state.vendor, 
            upload_id: this.state.upload_id, 
            pullsheet_id: this.state.pullsheet_id, 
            internal_po_number: this.state.internal_po_number, 
            external_po_number: this.state.external_po_number, 
            tracking_number: this.state.tracking_number, 
            name: this.state.name,
            sku: this.state.sku,
            order_status: this.state.order_status !== 0 ? this.state.order_status : null,
            tracking_status: this.state.tracking_status !== 0 ? this.state.tracking_status : null,
            acumatica_status: this.state.acumatica_status !== 0 ? this.state.acumatica_status : null,
            warehouse: this.state.warehouse !== "" ? this.state.warehouse : null
        }
        console.log(params);
        try {
            let res = await orderHelpers.getOrders(this.props.company, params, this.props.token);
            let orders = await this.formatOrders(res);
            this.setState({ orders: orders })
        }
        catch(err) {
            console.log(err);
            this.setState({ error: `Search Failed: ${err.message}` })
        }
        
        this.props.setLoading(false);
    }

    setOpenDetails = async(order = {}) => {
        if(order === {}) 
            this.setState({openDetails: order, detailsTab: 0});
        else 
            this.setState({openDetails: order.row, detailsTab: 0})
    }

    setTab = async(e) => {
        console.log(e);
        let tab = Number(e.target.name);
        if(tab > this.state.detailsTab) this.setState({direction: "left"})
        else this.setState({direction: "right"})
        this.setState({detailsTab: tab})
    }




    render(){
        return(
            <Box className={this.props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
                <OrderDetails {...this.props} direction={this.state.direction} setTab={this.setTab} tab={this.state.detailsTab} order={this.state.openDetails} setOpenDetails={this.setOpenDetails} />
                <Box className={this.props.isMobile ? "mobile-box" : "desktop-box"}>
                    <h1>Order Search</h1>
                    <OrderQueryForm
                        isMobile={this.props.isMobile}
                        vendors={this.state.vendorList} 
                        handleChange={this.handleChange}
                        handleDateChange={this.handleDateChange}
                        getOrders={this.getOrders}
                        startDate={this.state.start_date?.full}
                        endDate={this.state.end_date?.full}
                    />
                    <OrderSearchTable 
                        isMobile={this.props.isMobile}
                        orders={this.state.orders}
                        setOpenDetails={this.setOpenDetails}
                    />
                </Box>
            </Box>
        )
    }
}

export default OrderSearch;