
import {React} from 'react';
import Box from '@mui/material/Box';
import Warehouses from './Warehouses';
import User from './User'
function Tenant(props) {

    return(
        <Box display="flex" style={{width: "100%"}}>
            {props.isMobile ? 
                <User {...props} />
            : 
                <Warehouses {...props} />
            }
                        
                    </Box>
    )
}

export default Tenant;